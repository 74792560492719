import { Wrapper, Title, Logo, MoreInfo } from './styles';
import * as logo from '../../assets/logo.png'

const ComingSoon = () => {
  return (
    <Wrapper>
      <Logo src={logo} alt='Cairbairz limited logo' />      
      <Title>New website coming soon</Title>
      <MoreInfo>
        You can still contact us at <a href='tel:0116 284 9889'>0116 284 9889</a> or send a message to <a href='mailto:enquiries@cairbairz.co.uk'>enquiries@cairbairz.co.uk</a>
      </MoreInfo>
    </Wrapper>
  )
}

export default ComingSoon