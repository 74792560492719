import styled from "styled-components"

export const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 10px;

  font-family: 'Roboto', sans-serif;
`

export const Title = styled.h1`
  display: block;
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: 700;
`

export const Logo = styled.img`
  width: 100%;
  max-width: 500px;
`

export const MoreInfo = styled.p`
  display: block;
  width: 100%;
  max-width: 500px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
`
